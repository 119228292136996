import { useRouter } from 'next/router'
import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { cx } from '@emotion/css'
import { H1, LogoRoot } from './logo.styled'
import Link from '../../molecules/link'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'

const Logo = ({ className }) => {
	const router = useRouter()
	const { siteName: siteName } = useConfig()
	return (
		<LogoRoot className={cx('Logo-root', className)}>
			{router?.asPath.split('?')[0] === PAGE_PATH_HOME ? (
				<H1>
					<svg width="215" height="39" viewBox="0 0 215 39" fill="none" xmlns="http://www.w3.org/2000/svg">
						<title>{siteName}</title>
						<g>
							<polygon className="st0" points="210,0 204.6,0 211,6.6 214,6.6 	" />
							<path
								className="st0"
								d="M35.1,12.8c-4.2,0-7.5,1.7-10.1,5.1l-1.1,1.5l-1.1-1.5c-2.6-3.4-5.9-5.1-10.1-5.1c-3.5,0-6.5,1.2-9,3.7
		C1.2,19,0,21.9,0,25.4v12.7h2.8V25.4c0-2.7,1-5.1,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.7h2.8
		V25.4c0-2.7,1-5,2.9-7c1.9-1.9,4.2-2.9,7-2.9c2.7,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7v12.7h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C41.6,14,38.7,12.8,35.1,12.8z"
							/>
							<path
								className="st0"
								d="M67.9,12.7h-0.5v0c-3.3,0.1-6.1,1.3-8.5,3.7c-2.5,2.5-3.7,5.4-3.7,8.9c0,3.5,1.2,6.5,3.7,9
		c2.5,2.5,5.4,3.7,9,3.7c3.5,0,6.5-1.2,8.9-3.7c2.5-2.5,3.7-5.4,3.7-9c0-3.5-1.2-6.4-3.7-8.9C74.3,14,71.4,12.7,67.9,12.7z
		 M77.7,25.4c0,2.7-1,5-2.9,7c-1.9,1.9-4.2,2.9-7,2.9c-2.7,0-5.1-1-7-2.9C59,30.5,58,28.1,58,25.4c0-2.7,1-5,2.9-7
		c1.9-1.9,4.2-2.9,6.9-2.9s5,1,7,2.9C76.7,20.4,77.7,22.7,77.7,25.4z"
							/>
							<path
								className="st0"
								d="M122.4,12.8c-4.2,0-7.5,1.7-10.1,5.1l-1.1,1.5l-1.1-1.5c-2.6-3.4-5.9-5.1-10.1-5.1c-3.5,0-6.5,1.2-9,3.7
		c-2.5,2.5-3.7,5.4-3.7,8.9v12.7h2.8V25.4c0-2.7,1-5.1,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9s5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.7h2.8
		V25.4c0-2.7,1-5,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7v12.7h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C128.9,14,126,12.8,122.4,12.8z"
							/>
							<path
								className="st0"
								d="M156.4,12.8h-1.2l0.1,0.1c-3,0.2-5.6,1.4-7.8,3.7c-2.5,2.5-3.7,5.4-3.7,8.9c0,3.5,1.2,6.5,3.7,9
		c2.5,2.5,5.4,3.7,9,3.7c3.5,0,6.5-1.2,8.9-3.7c2.5-2.5,3.7-5.4,3.7-9c0-3.5-1.2-6.4-3.7-8.9C162.9,14,160,12.8,156.4,12.8z
		 M156.4,15.6c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7c0,2.7-1,5-2.9,7c-1.8,1.8-4,2.8-6.5,2.9h-0.5c-2.7,0-5.1-1-7-2.9
		c-1.9-1.9-2.9-4.2-2.9-6.9c0-2.7,1-5.1,2.9-7C151.4,16.6,153.7,15.6,156.4,15.6z"
							/>
							<path
								className="st0"
								d="M189.2,12.8c-3.5,0-6.5,1.2-9,3.7c-2.5,2.5-3.7,5.4-3.7,8.9v12.7h2.8V25.4c0-2.7,1-5,2.9-7
		c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.6h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C195.7,14,192.7,12.8,189.2,12.8z"
							/>
							<rect x="211.6" y="12.8" className="st0" width="2.8" height="25.3" />
						</g>
					</svg>
				</H1>
			) : (
				<Link href={PAGE_PATH_HOME}>
					<svg width="215" height="39" viewBox="0 0 215 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<title>{siteName}</title>
						<g>
							<polygon className="st0" points="210,0 204.6,0 211,6.6 214,6.6 	" />
							<path
								className="st0"
								d="M35.1,12.8c-4.2,0-7.5,1.7-10.1,5.1l-1.1,1.5l-1.1-1.5c-2.6-3.4-5.9-5.1-10.1-5.1c-3.5,0-6.5,1.2-9,3.7
		C1.2,19,0,21.9,0,25.4v12.7h2.8V25.4c0-2.7,1-5.1,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.7h2.8
		V25.4c0-2.7,1-5,2.9-7c1.9-1.9,4.2-2.9,7-2.9c2.7,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7v12.7h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C41.6,14,38.7,12.8,35.1,12.8z"
							/>
							<path
								className="st0"
								d="M67.9,12.7h-0.5v0c-3.3,0.1-6.1,1.3-8.5,3.7c-2.5,2.5-3.7,5.4-3.7,8.9c0,3.5,1.2,6.5,3.7,9
		c2.5,2.5,5.4,3.7,9,3.7c3.5,0,6.5-1.2,8.9-3.7c2.5-2.5,3.7-5.4,3.7-9c0-3.5-1.2-6.4-3.7-8.9C74.3,14,71.4,12.7,67.9,12.7z
		 M77.7,25.4c0,2.7-1,5-2.9,7c-1.9,1.9-4.2,2.9-7,2.9c-2.7,0-5.1-1-7-2.9C59,30.5,58,28.1,58,25.4c0-2.7,1-5,2.9-7
		c1.9-1.9,4.2-2.9,6.9-2.9s5,1,7,2.9C76.7,20.4,77.7,22.7,77.7,25.4z"
							/>
							<path
								className="st0"
								d="M122.4,12.8c-4.2,0-7.5,1.7-10.1,5.1l-1.1,1.5l-1.1-1.5c-2.6-3.4-5.9-5.1-10.1-5.1c-3.5,0-6.5,1.2-9,3.7
		c-2.5,2.5-3.7,5.4-3.7,8.9v12.7h2.8V25.4c0-2.7,1-5.1,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9s5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.7h2.8
		V25.4c0-2.7,1-5,2.9-7c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5.1,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7v12.7h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C128.9,14,126,12.8,122.4,12.8z"
							/>
							<path
								className="st0"
								d="M156.4,12.8h-1.2l0.1,0.1c-3,0.2-5.6,1.4-7.8,3.7c-2.5,2.5-3.7,5.4-3.7,8.9c0,3.5,1.2,6.5,3.7,9
		c2.5,2.5,5.4,3.7,9,3.7c3.5,0,6.5-1.2,8.9-3.7c2.5-2.5,3.7-5.4,3.7-9c0-3.5-1.2-6.4-3.7-8.9C162.9,14,160,12.8,156.4,12.8z
		 M156.4,15.6c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.3,2.9,7c0,2.7-1,5-2.9,7c-1.8,1.8-4,2.8-6.5,2.9h-0.5c-2.7,0-5.1-1-7-2.9
		c-1.9-1.9-2.9-4.2-2.9-6.9c0-2.7,1-5.1,2.9-7C151.4,16.6,153.7,15.6,156.4,15.6z"
							/>
							<path
								className="st0"
								d="M189.2,12.8c-3.5,0-6.5,1.2-9,3.7c-2.5,2.5-3.7,5.4-3.7,8.9v12.7h2.8V25.4c0-2.7,1-5,2.9-7
		c1.9-1.9,4.2-2.9,6.9-2.9c2.7,0,5,1,7,2.9c1.9,1.9,2.9,4.2,2.9,7v12.6h2.8V25.4c0-3.5-1.2-6.4-3.7-8.9
		C195.7,14,192.7,12.8,189.2,12.8z"
							/>
							<rect x="211.6" y="12.8" className="st0" width="2.8" height="25.3" />
						</g>
					</svg>
				</Link>
			)}
		</LogoRoot>
	)
}

export default Logo
