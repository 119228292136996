/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.1 7.9c-.2-.2-.5-.4-.8-.4h-3.8V6c0-1.4-1.1-2.5-2.5-2.5H9C7.6 3.5 6.5 4.6 6.5 6v1.5H2.7c-.3 0-.6.1-.8.4-.2.2-.3.6-.3.9l1.8 10.8c.1.5.5.9 1.1.9h15c.5 0 1-.4 1.1-.9l1.8-10.8c0-.3-.1-.7-.3-.9zM7.5 6c0-.8.7-1.5 1.5-1.5h6c.8 0 1.5.7 1.5 1.5v1.5h-9V6zm12.1 13.4c0 .1-.1.1 0 0l-15.1.1s-.1 0-.1-.1L2.6 8.6l.1-.1h18.6l.1.1-1.8 10.8z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
