/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M14.7 11.8c1.6-.9 2.6-2.6 2.6-4.6 0-2.9-2.4-5.3-5.3-5.3S6.7 4.3 6.7 7.2c0 2 1.1 3.7 2.6 4.6-3.6 1.1-6.2 4.5-6.2 8.5v1.2c0 .3.3.6.6.6s.6-.3.6-.6v-1.2c0-4.3 3.5-7.7 7.7-7.7 4.3 0 7.7 3.5 7.7 7.7v1.2c0 .3.3.6.6.6s.6-.3.6-.6v-1.2c0-4-2.6-7.3-6.2-8.5zM7.8 7.2C7.8 5 9.7 3.1 12 3.1c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2c-2.3-.1-4.2-2-4.2-4.3z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
