/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 21.2c-.5 0-1-.2-1.4-.6l-7.1-7.4c-2.3-2.4-2.3-6.3 0-8.7 1.1-1.2 2.7-1.8 4.3-1.8 1.6 0 3.1.6 4.2 1.7 1.1-1.1 2.6-1.7 4.2-1.7s3.1.7 4.3 1.8c1.1 1.2 1.7 2.7 1.7 4.4 0 1.6-.6 3.2-1.7 4.4-.8.8-1.6 1.7-2.4 2.5-1.5 1.6-3.1 3.3-4.8 4.9-.4.4-.8.5-1.3.5zM7.8 3.7c-1.4 0-2.6.5-3.6 1.5-2 2-2 5.3 0 7.4l7.1 7.4c.4.4.9.4 1.3 0 1.6-1.6 3.2-3.3 4.8-4.9.8-.8 1.6-1.7 2.4-2.5.9-1 1.5-2.3 1.5-3.7 0-1.4-.5-2.7-1.5-3.7s-2.2-1.5-3.6-1.5c-1.3 0-2.6.6-3.6 1.5l-.3.3c-.1.1-.2.1-.3.1-.1 0-.3-.1-.4-.1l-.3-.3c-.9-.9-2.1-1.5-3.5-1.5z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
