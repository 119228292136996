/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const Bag2FilledHeaderIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 7.7v-.2H7.5V6c0-.8.7-1.5 1.5-1.5h3.7c.2-.3.3-.7.5-1H9C7.6 3.5 6.5 4.6 6.5 6v1.5H2.7c-.3 0-.6.1-.8.4-.2.2-.3.6-.3.9l1.8 10.8c.1.5.5.9 1.1.9h15c.5 0 1-.4 1.1-.9l.7-4.3c-.5.1-1 .2-1.6.2-4.2-.1-7.7-3.5-7.7-7.8z" />
	</svg>
))
Bag2FilledHeaderIconSvg.displayName = 'Bag2FilledHeaderIconSvg'

const Bag2FilledHeaderIcon = forwardRef((props, ref) => (
	<Icon component={Bag2FilledHeaderIconSvg} ref={ref} {...props} />
))
Bag2FilledHeaderIcon.displayName = 'Bag2FilledHeaderIcon'

Bag2FilledHeaderIcon.defaultProps = {
	...Icon.defaultProps,
}
Bag2FilledHeaderIcon.propTypes = {
	...Icon.propTypes,
}

export default Bag2FilledHeaderIcon
export { Bag2FilledHeaderIconSvg }
